import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'

const pageMeta = {
  title: 'Posts',
  type: 'posts',
  description: 'Lou Groshek blog posts',
  keywords: `lou, groshek, tango, queer tango, web development, graduate school, posts, blog`,
}

const Posts = ({ location }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "post" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          nodes {
            id
            fields {
              slug
            }
            frontmatter {
              author {
                first_name
                last_name
                photo
              }
              date(formatString: "YYYY-MM-DD")
              title
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: AUTO
                    height: 120
                    width: 120
                  )
                  resize(
                    cropFocus: CENTER
                    grayscale: true
                    height: 200
                    width: 200
                    fit: FILL
                  ) {
                    src
                    tracedSVG
                    width
                    height
                    aspectRatio
                    originalName
                  }
                }
                publicURL
              }
            }
            html
            wordCount {
              words
            }
            excerpt(format: PLAIN)
          }
          totalCount
        }
      }
    `
  )

  return (
    <Layout location={pageMeta.location} pageType={pageMeta.type}>
      <Row className="heading row">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <h2>{pageMeta.title}</h2>
        </Col>
      </Row>
      <Row className="content">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          {allMarkdownRemark.nodes.map((el) => {
            const articleImage = getImage(el.frontmatter.image)
            return (
              <div className="post-prev" key={`post-${el.id}`}>
                <div className="image-wrap">
                  <GatsbyImage image={articleImage} alt="my new image" />
                </div>
                <div className="link-meta">
                  <Link to={`/posts${el.fields.slug}`}>
                    <h3>{el.frontmatter.title}</h3>
                  </Link>
                  <div className="date">{el.frontmatter.date}</div>
                  <div className="author">{`${el.frontmatter.author.first_name} ${el.frontmatter.author.last_name}`}</div>
                  <p>{el.excerpt}</p>
                </div>
              </div>
            )
          })}
        </Col>
      </Row>
    </Layout>
  )
}

export default Posts

export const Head = () => <SEO meta={{ ...pageMeta }} />
